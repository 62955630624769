import { GTM4Params, sendGAEvent } from '@lib/gtag';

import { useAppSelector } from '@hooks/redux/useAppSelector';

import { selectUserStats } from '@store/meStats/selector';

export const useGAEvents = () => {
  const userStats = useAppSelector(selectUserStats);

  const createBaseUserObject = () => ({
    kp_user_id: userStats.userId ?? '',
    user_location_name: userStats.userLocation,
    total_ads: userStats.totalAds.toString(),
    user_created: userStats.userCreated,
    user_type: userStats.userType,
    user_group: userStats.userGroup,
  });

  const sendGAEventWithData = (eventData: GTM4Params<{ event: string }>) => {
    sendGAEvent(eventData);
  };

  return {
    createBaseUserObject,
    sendGAEventWithData,
    userStats,
  };
};
